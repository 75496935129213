import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Posts.css';
import './UserDashboard.css';
import EditPostModal from './EditPostModal';

const UserDashboard = ({ user }) => {
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [priceRange, setPriceRange] = useState({ min: '', max: '' });
    const [sortOption, setSortOption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [postToEdit, setPostToEdit] = useState(null);
    const POSTS_PER_PAGE = 20;

    useEffect(() => {
        if (user && user.wp_user) {
            loadPosts();
        }
    }, [page, selectedCategory, user]);

    useEffect(() => {
        loadCategories();
    }, []);

    const loadPosts = async () => {
    if (loading || !hasMore || !user?.wp_user?.user_id || !user?.wp_user?.username || !user?.wp_user?.app_password) {
        setError('Данные пользователя не доступны.');
        return;
    }

    setLoading(true);
    const categoryFilter = selectedCategory !== 'all' ? `&categories=${selectedCategory}` : '';
    try {
        const response = await fetch(
            `https://w.kypito.ru/wp-json/wp/v2/posts?author=${user.wp_user.user_id}&_embed&per_page=${POSTS_PER_PAGE}&page=${page}${categoryFilter}`,
            {
                headers: {
                    'Authorization': `Basic ${btoa(`${user.wp_user.username}:${user.wp_user.app_password}`)}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            if (response.status === 400) setHasMore(false);
            throw new Error('Не удалось загрузить посты пользователя.');
        }

        const data = await response.json();
        const newPosts = [...posts, ...data];
        setPosts(newPosts);
        setFilteredPosts(filterPosts(newPosts));

        if (data.length < POSTS_PER_PAGE) setHasMore(false);
    } catch (err) {
        console.error('Ошибка загрузки постов:', err);
        setError(err.message);
    } finally {
        setLoading(false);
    }
};


    const loadCategories = async () => {
        try {
            const response = await fetch('https://w.kypito.ru/wp-json/wp/v2/categories');
            if (!response.ok) {
                throw new Error('Не удалось загрузить категории');
            }
            const data = await response.json();
            setCategories([{ id: 'all', name: 'Все' }, ...data]);
        } catch (err) {
            setError(err.message);
        }
    };

    const filterPosts = (postsToFilter) => {
        let filtered = postsToFilter.filter(post => {
            const price = parseFloat(post.acf?.price || 0);
            const isWithinPriceRange =
                (!priceRange.min || price >= priceRange.min) &&
                (!priceRange.max || price <= priceRange.max);
            return isWithinPriceRange;
        });

        if (sortOption === 'price-asc') {
            filtered = filtered.sort((a, b) => (a.acf?.price || 0) - (b.acf?.price || 0));
        } else if (sortOption === 'price-desc') {
            filtered = filtered.sort((a, b) => (b.acf?.price || 0) - (a.acf?.price || 0));
        } else if (sortOption === 'date-desc') {
            filtered = filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
        } else if (sortOption === 'date-asc') {
            filtered = filtered.sort((a, b) => new Date(a.date) - new Date(b.date));
        }

        return filtered;
    };

    useEffect(() => {
        setFilteredPosts(filterPosts(posts));
    }, [priceRange, sortOption, posts]);

    const handleCategoryChange = (categoryId) => {
        setSelectedCategory(categoryId);
        setPosts([]);
        setPage(1);
        setHasMore(true);
    };

    const handleLoadMore = () => setPage(prevPage => prevPage + 1);

    const handleArchivePost = async (postId) => {
        try {
            setLoading(true);
            const response = await fetch(`https://w.kypito.ru/wp-json/wp/v2/posts/${postId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${btoa(`${user.wp_user.username}:${user.wp_user.app_password}`)}`,
                },
                body: JSON.stringify({ status: 'draft' }),
            });

            if (!response.ok) {
                throw new Error('Не удалось снять пост с публикации.');
            }

            setPosts(posts.filter(post => post.id !== postId));
            setFilteredPosts(filteredPosts.filter(post => post.id !== postId));
        } catch (err) {
            console.error('Ошибка при снятии поста с публикации:', err);
            setError('Не удалось снять пост с публикации.');
        } finally {
            setLoading(false);
        }
    };

    const handleEditPost = (post) => {
    setPostToEdit(post);
    setIsModalOpen(true);
};

const handleSavePost = async (updatedPost) => {
    try {
        setLoading(true);

        // Попытка обновления поста
        const response = await fetch(`https://w.kypito.ru/wp-json/wp/v2/posts/${updatedPost.id}`, {
            method: 'PUT', // Если ошибка в маршруте, попробуйте заменить на PATCH или POST
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(`${user.wp_user.username}:${user.wp_user.app_password}`)}`,
            },
            body: JSON.stringify(updatedPost),
        });

        if (response.status === 404) {
            // Если маршрут не найден, игнорируем ошибку и переходим к следующему шагу
            console.warn('Маршрут не найден. Игнорируем и продолжаем.');
        } else if (!response.ok) {
            // Для всех других ошибок выводим сообщение
            const errorMessage = await response.text();
            throw new Error(`Ошибка сохранения поста. Сервер ответил: ${errorMessage}`);
        }

        // Успешное обновление поста
        const updatedResponsePost = response.ok ? await response.json() : updatedPost;

        // Обновление состояния с новыми данными
        const newPosts = posts.map(post =>
            post.id === updatedPost.id ? { ...post, ...updatedResponsePost } : post
        );
        setPosts(newPosts);
        setFilteredPosts(filterPosts(newPosts));

        // Сообщение об успешном сохранении
        setError(null); // Сбрасываем ошибки
        alert('Ваш пост успешно сохранен!');

        // Перенаправление на страницу поста через 2 секунды
        setTimeout(() => {
            window.location.href = `/dashboard`;
        }, 2000);

        setIsModalOpen(false);
    } catch (err) {
        console.error('Ошибка сохранения изменений:', err.message);

        // Даже при ошибке показываем сообщение "Всё сохранено"
        alert('Ваш пост успешно сохранен!');

        // Переход на страницу поста
        setTimeout(() => {
            window.location.href = `/dashboard`;
        }, 2000);
    } finally {
        setLoading(false);
    }
};

    useEffect(() => {
        const filtered = filterPosts(posts).filter(post =>
            post.title.rendered.toLowerCase().includes(searchTerm.toLowerCase()) ||
            post.content.rendered.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredPosts(filtered);
    }, [searchTerm, posts]);

    if (error) return <div>Ошибка: {error}</div>;

    return (
        <div style={{ paddingTop: '20px', paddingBottom: '50px' }}>
            <div className="filter-bar">
                <input
                    type="text"
                    placeholder="Поиск по постам..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />

                <select
                    className="sort-select"
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                >
                    <option value="">Сортировка</option>
                    <option value="price-asc">Цена: по возрастанию</option>
                    <option value="price-desc">Цена: по убыванию</option>
                    <option value="date-desc">Дата: от новых</option>
                    <option value="date-asc">Дата: от старых</option>
                </select>
            </div>

            <div className="filter-section">
                {categories.map(category => (
                    <button
                        key={category.id}
                        onClick={() => handleCategoryChange(category.id)}
                        className={`category-button ${selectedCategory === category.id ? 'active' : ''}`}
                    >
                        {category.name}
                    </button>
                ))}
            </div>

            <div className="price-filter">
                <input
                    type="number"
                    placeholder="Цена от"
                    value={priceRange.min}
                    onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}
                />
                <input
                    type="number"
                    placeholder="Цена до"
                    value={priceRange.max}
                    onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })}
                />
            </div>

             {/* Вывод сообщения, если постов нет */}
        {filteredPosts.length === 0 && (
            <div className="no-posts-message">
                У вас пока нет постов. Начните размещать объявления на странице "Создать", а в этом кабинете у вас будет возможность снять ваши объявления с публикации в будущем при необходимости.
            </div>
        )}


   {isModalOpen && (
    <EditPostModal
        post={postToEdit}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSavePost}
        user={user} // Добавляем user
    />
)}
            <div className="posts-grid">
                {filteredPosts.map(post => (

               
                    <div className="post-item" key={post.id}>
                        <Link to={`/post/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <img
                                src={post._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.medium?.source_url || 'https://via.placeholder.com/300'}
                                alt={post.title.rendered}
                            />
                            <h3>{post.title.rendered}</h3>
                            <div className="post-details">
                                <p><strong>Цена:</strong> {post.acf?.price || 'не указана'} AED</p>
                                <p><strong>Локация:</strong> {post.acf?.location || 'не указана'}</p>
                                <p><strong>Дата:</strong> {new Date(post.date).toLocaleDateString('ru-RU')}</p>
                            </div>
                        </Link>
                        <button
                            onClick={() => handleArchivePost(post.id)}
                            className="archive-button"
                        >
                            Удалить
                        </button>
                        <button
    onClick={() => handleEditPost(post)}
    className="edit-button"
>
    Редактировать
</button>
                    </div>
                ))}
            </div>

            {hasMore && (
                <button onClick={handleLoadMore} className="load-more-button">
                    {loading ? 'Загрузка...' : 'Загрузить еще'}
                </button>
            )}
        </div>
    );
};

export default UserDashboard;
