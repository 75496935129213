import React, { useEffect, useState } from 'react'; 
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import './App.css';
import { AiOutlineHome, AiOutlinePlusCircle, AiOutlineUser } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import Posts from './Posts';
import PostDetail from './PostDetail';
import CreatePost from './CreatePost';
import UserDashboard from './UserDashboard';
import Analytics from './Analytics'; // Импортируем компонент аналитики
import useLazyLoadImages from './useLazyLoadImages'; // Lazy загрузка 
import AboutProject from './AboutProject'; // Импортируем новый компонент
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Импорт новой иконки
import ProtectedRoute from './ProtectedRoute'; // Импортируем компонент
import { isMobile } from 'react-device-detect';

function App() {
    const [user, setUser] = useState(null); // Состояние для хранения данных пользователя
    const [searchTerm, setSearchTerm] = useState(''); // Состояние для текста поиска
    const [error, setError] = useState({ message: null, link: null }); // Состояние для ошибок
    
    const [showPopup, setShowPopup] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

   

    // Подключаем хук ленивой загрузки
    useLazyLoadImages();


    useEffect(() => {
        if (window.Telegram?.WebApp) {
            const tg = window.Telegram.WebApp;
            tg.ready(); // Инициализация Telegram WebApp
            tg.expand(); // Увеличиваем Web App на всю страницу
         

            const initData = tg.initData; // Получаем initData из Telegram

             //console.log('Получено initData:', initData);
            console.log('Получено initData');

            // Отправляем запрос для авторизации пользователя
            fetch('https://api.kypito.ru/api/auth', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ initData }),
            })
                .then((response) => {
                    if (!response.ok) {
                        // Если пользователь не авторизован
                        setError({
    message: 'Привет! Вы зашли без авторизации через Telegram и сможете только просматривать объявления.',
    link: {
        text: 'Войдите через телеграмм бота чтобы создавать или редактировать объявления.',
        url: 'https://t.me/Kypito_bot',
    },
});
                        throw new Error('Пользователь не авторизован.');
                    }
                    return response.json(); // Если авторизация успешна, возвращаем JSON
                })
                .then((data) => {
                    //console.log('Ответ сервера авторизации:', data);
                    console.log('Ответ сервера авторизации');
                    if (data.status === 'ok' && data.wp_user) {
                        // Если пользователь успешно авторизован
                        setUser({ ...data.user, wp_user: data.wp_user });
                        setShowPopup(true); // Показываем поп-ап
                         // console.log('Пользователь успешно авторизован:', { ...data.user, wp_user: data.wp_user });
                        console.log('Пользователь успешно авторизован');
                    } else {
                        setError('Авторизация не удалась. Попробуйте позже.');
                        //console.error('Ошибка авторизации:', data.message);
                        console.error('Ошибка авторизации');
                    }
                })
                .catch((err) => {
                    //console.error('Ошибка при авторизации:', err);
                    console.error('Ошибка при авторизации');
                });
        } else {
            // Если Telegram WebApp недоступен
            setError({ message: 'Telegram WebApp недоступен. Проверьте правильность окружения.', link: null });
            console.error('Telegram WebApp недоступен.');
        }
    }, []);


    const [deferredPrompt, setDeferredPrompt] = useState(null);

useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
        event.preventDefault(); // Предотвращаем автоматическое отображение подсказки
        setDeferredPrompt(event); // Сохраняем событие для использования позже
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
}, []);
    
const [showAddToHomeScreenPopup, setShowAddToHomeScreenPopup] = useState(false);

useEffect(() => {
    const timer = setTimeout(() => {
        setShowAddToHomeScreenPopup(true);
    }, 10000);

    return () => clearTimeout(timer);
}, []);

    return (
        <Router>
    
            <div className="App">
                {/* Отображение ошибок */}
              {error && error.message && error.message.trim() !== '' && (
    <div className="error-banner">
        <div
            style={{
                backgroundColor: '#ffe0e0',
                color: '#900',
                padding: '5px',
                borderRadius: '3px',
                margin: '0px 0',
                textAlign: 'center',
                fontSize: '14px',
                lineHeight: '1.2', // Уменьшаем расстояние между строками
            }}
        >
            <p style={{ marginTop: '0px', marginBottom: '0px' }}>{error.message}</p>
            {error.link && (
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                    <a href={error.link.url} target="_blank" rel="noopener noreferrer">
                        {error.link.text}
                    </a>
                </p>
            )}
        </div>
    </div>
)}

{showAddToHomeScreenPopup && (
    <div className="popup-container">
        <div className="popup">
            <h4>Добавить Купито на главный экран</h4>
            <p>Для удобства сохраните приложение на главный экран.</p>

            {isMobile ? (
                // Для мобильных устройств
                <button
                    onClick={() => {
                        if (window.Telegram?.WebApp) {
                            const tg = window.Telegram.WebApp;
                            tg.addToHomeScreen();
                        }
                    }}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginBottom: '10px',
                    }}
                >
                    Сохранить Купито на главном экране 📌
                </button>
            ) : (
                // Для десктопов
                <button
                    onClick={async () => {
                        if (deferredPrompt) {
                            deferredPrompt.prompt(); // Показываем предложение установки
                            const { outcome } = await deferredPrompt.userChoice; // Ждём выбора пользователя
                            console.log(`Пользователь выбрал: ${outcome}`);
                            setDeferredPrompt(null); // Сбрасываем событие
                        }
                    }}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginBottom: '10px',
                    }}
                >
                    Установить Купито на рабочий стол
                </button>
            )}

            <button
                onClick={() => setShowAddToHomeScreenPopup(false)}
                style={{
                    padding: '10px 20px',
                    backgroundColor: '#ccc',
                    color: '#000',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}
            >
                Закрыть
            </button>
        </div>
    </div>
)}
                {/* Верхняя панель с поиском 
                <div className="App-header-fixed">
                    <BiSearch size={20} className="search-icon" />
                    <input
                        type="text"
                        placeholder="Поиск по постам..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>*/}

                {/* Добавляем отступ под панель */}
                <div style={{ marginTop: '0px' }}>
            <header className="App-header">
                {/* Всплывающее окно */}
                {showPopup && (
                    <div className="popup-container">
                        <div className="popup">
                            <h4>Привет! Вы успешно авторизованы!</h4>
                            <p>Теперь вы можете создавать объявления.</p>
                            <button className="close-popup-btn" onClick={handleClosePopup}>
                                Закрыть
                            </button>


                        </div>
                    </div>
                )}

               

                {/* Основные маршруты приложения */}
               <Routes>
    <Route path="/" element={<Posts searchTerm={searchTerm} />} />
    <Route path="/post/:id" element={<PostDetail />} />
    <Route
        path="/create"
        element={
            <ProtectedRoute user={user}>
                <CreatePost user={user} />
            </ProtectedRoute>
        }
    />
    <Route
        path="/dashboard"
        element={
            <ProtectedRoute user={user}>
                <UserDashboard user={user} />
            </ProtectedRoute>
        }
    />
    <Route path="/support" element={<AboutProject />} /> {/* Новый маршрут */}
</Routes>
            </header>
        </div>

                {/* Нижняя навигационная панель */}
                <footer className="App-footer">
    <Link to="/" className="footer-link">
        <AiOutlineHome size={30} />
        <span>Домой</span>
    </Link>

    {user && (
        <Link to="/dashboard" className="footer-link">
            <AiOutlineUser size={30} />
            <span>Кабинет</span>
        </Link>
    )}

    {user && (
        <Link to="/create" className="footer-link">
            <AiOutlinePlusCircle size={30} />
            <span>Создать</span>
        </Link>
    )}

    <Link to="/support" className="footer-link">
        <AiOutlineInfoCircle size={30} />
        <span>О проекте</span>
    </Link>
</footer>
            </div>
            
                <Analytics /> {/* Подключаем компонент аналитики */}
        </Router>
    );
}



export default App;