import React, { useState, useEffect } from 'react';
import './CreatePost.css'; // Подключение стилей
import { useNavigate } from 'react-router-dom';

const CreatePost = ({ user }) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [previewImage, setPreviewImage] = useState(null);
    const [additionalImages, setAdditionalImages] = useState([]);
    const navigate = useNavigate();
    const [price, setPrice] = useState('');
    const [location, setLocation] = useState('');
    const [status, setStatus] = useState('Новый');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [loadingImages, setLoadingImages] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [postLink, setPostLink] = useState(null);

    // Валидация недопустимых слов
const forbiddenWords = [
  'кокаин', 'кокс', 'трава', 'гашиш', 'героин', 'спайс', 'метадон', 'марихуана',
  'экстази', 'лсд', 'гриб', 'псилоцибин', 'амфетамин', 'дезоморфин', 'мефедрон',
  'метамфетамин', 'фен', 'наркотик', 'аптечка', 'бошки', 'спайсы', 'план',
  'растаман', 'лигафишка', 'амф', 'мдма', 'опий', 'психоделик', 'кристалл', 
  'соль', 'крокодил', 'белый порошок', 'синтетика',
  'cocaine', 'weed', 'hash', 'heroin', 'spice', 'methadone', 'marijuana',
  'ecstasy', 'lsd', 'shrooms', 'psilocybin', 'amphetamine', 'desomorphine', 'meth',
  'crack', 'ice', 'drug', 'narcotic', 'ganja', 'rasta', 'synthetic', 'molly',
  'mdma', 'opium', 'psychedelic', 'crystal', 'bath salts', 'methamphetamine',
  'fentanyl', 'opioid', 'speed'
];

const validateInput = (text) => {
  const words = forbiddenWords.join('|');
  const regex = new RegExp(`\\b(${words})\\b`, 'i'); // Ищем только полные слова (с \b как границы слова)
  return !regex.test(text);
};

const validatePrice = (price) => {
    // Убираем ведущие нули и проверяем, что цена больше 1 и соответствует ограничениям по длине
    const priceNumber = parseFloat(price);

    if (priceNumber < 2) {
        setError('Цена не может быть меньше 2 AED.');
        return false;
    }

    // Проверяем, что введено корректное число и оно не начинается с нуля
    const priceString = price.trim();
    if (!/^\d+$/.test(priceString)) {
        setError('Цена должна быть числом.');
        return false;
    }

    // Ограничение на количество знаков (например, максимум 10 знаков)
    if (priceString.length > 10) {
        setError('Цена не может содержать более 10 цифр.');
        return false;
    }

    return true;
};


    // Ограничения на длину
    const MAX_TITLE_LENGTH = 36;
    const MAX_DESCRIPTION_LENGTH = 500;
    const MAX_LOCATION_LENGTH = 50;

    // Ограничения на количество фото
    const MAX_ADDITIONAL_IMAGES = 10;

    // Валидация Application Password
    const validateAppPassword = () => {
        if (!user?.wp_user?.app_password) {
            setError('Application Password отсутствует. Авторизуйтесь снова.');
            return false;
        }
        return true;
    };

    // Загрузка изображения на сервер
    const uploadImage = async (imageFile) => {
        setLoadingImages(true);
        const formData = new FormData();
        formData.append('file', imageFile);

        try {
            const response = await fetch('https://w.kypito.ru/wp-json/wp/v2/media', {
                method: 'POST',
                headers: {
                    'Authorization': `Basic ${btoa(`${user.wp_user.username}:${user.wp_user.app_password}`)}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Ошибка загрузки изображения.');
            }

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            setError('Ошибка при загрузке изображения: ' + error.message);
            throw error;
        } finally {
            setLoadingImages(false);
        }
    };

    // Создание нового поста
    const createPost = async (featuredMediaId, additionalMediaUrls) => {
        const galleryHTML = additionalMediaUrls
            .map((url) => `<img src="${url}" alt="Изображение" />`)
            .join('');

        const postData = {
            title,
            content: content + galleryHTML,
            status: 'publish',
            featured_media: featuredMediaId,
            categories: [category],
        };

        try {
            const response = await fetch('https://w.kypito.ru/wp-json/wp/v2/posts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${btoa(`${user.wp_user.username}:${user.wp_user.app_password}`)}`,
                },
                body: JSON.stringify(postData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Не удалось создать пост.');
            }

            const postResponse = await response.json();
            const postId = postResponse.id;

            // Обновление ACF полей через ACF API
            const acfData = {
                fields: {
                    price,
                    location,
                    status,
                },
            };

            const acfResponse = await fetch(`https://w.kypito.ru/wp-json/acf/v3/posts/${postId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${btoa(`${user.wp_user.username}:${user.wp_user.app_password}`)}`,
                },
                body: JSON.stringify(acfData),
            });

            if (!acfResponse.ok) {
                throw new Error('Ошибка обновления полей ACF.');
            }

            // Сохранение ссылки на пост
            navigate(`/post/${postId}`);

            // Очистка всех полей
            setTitle('');
            setContent('');
            setPreviewImage(null);
            setAdditionalImages([]);
            setPrice('');
            setLocation('');
            setStatus('Новый');
            setCategory('');
        } catch (error) {
            setError('Ошибка при создании поста: ' + error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        try {
            if (!validateAppPassword()) return;

            if (!validateInput(title) || !validateInput(content) || !validateInput(location)) {
                setError('Ваш текст содержит запрещенные слова.');
                return;
            }

           if (!validatePrice(price)) {
                 return;
            }
            
            if (title.length > MAX_TITLE_LENGTH) {
    setError('Превышен лимит символов в поле "Заголовок".');
    return;
}

if (content.length > MAX_DESCRIPTION_LENGTH) {
    setError('Превышен лимит символов в поле "Описание".');
    return;
}

if (location.length > MAX_LOCATION_LENGTH) {
    setError('Превышен лимит символов в поле "Локация".');
    return;
}


            if (additionalImages.length > MAX_ADDITIONAL_IMAGES) {
                setError(`Вы можете загрузить не более ${MAX_ADDITIONAL_IMAGES} дополнительных изображений.`);
                return;
            }

            let featuredMediaId = null;
            const additionalMediaUrls = [];

            if (previewImage) {
                const previewImageData = await uploadImage(previewImage);
                featuredMediaId = previewImageData.id;
            }

            for (const image of additionalImages) {
                const additionalImageData = await uploadImage(image);
                additionalMediaUrls.push(additionalImageData.source_url);
            }

            await createPost(featuredMediaId, additionalMediaUrls);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch('https://w.kypito.ru/wp-json/wp/v2/categories?per_page=20');
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
            }
        } catch (error) {
            console.error('Ошибка загрузки категорий:', error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const removeAdditionalImage = (index) => {
        setAdditionalImages(additionalImages.filter((_, i) => i !== index));
    };

    const removePreviewImage = () => {
        setPreviewImage(null);
    };

    return (
        <div className="create-post-container">
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit}>
                <h2>Создать новое объявление</h2>

                

                

                <label>Заголовок:</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    maxLength={MAX_TITLE_LENGTH}
                    required
                    className="form-input"
                />
<p className="reminder">
                    Укажите контактные данные в описании товара, если у вас Telegram Premium или если у вас нет ника в формате @username. В противном случае покупатели просто не смогут с вами связаться.
                </p>
                <label>Описание:</label>
                <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                    required
                    className="form-textarea"
                />

              <div className="acf-fields">
    <div className="field-row">
        {/* Блок для "Цена" и "Статус" */}
        <div className="field-item">
            <label>Цена (AED):</label>
            <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                min={2}
                required
                className="form-input"
            />
        </div>
        <div className="field-item">
            <label>Состояние товара:</label>
            <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="form-select"
            >
                <option>Новый</option>
                <option>Б/у</option>
                <option>Много товаров</option>
            </select>
        </div>
    </div>

    <div className="field-row">
        {/* Блок для "Локация" и "Категория" */}
        <div className="field-item">
            <label>Локация:</label>
            <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                maxLength={MAX_LOCATION_LENGTH}
                required
                className="form-input"
            />
        </div>
        <div className="field-item">
            <label>Категория:</label>
            <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="form-select"
                required
            >
                <option value="">Выберите категорию</option>
                {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                        {cat.name}
                    </option>
                ))}
            </select>
        </div>
    </div>
</div>



                <div className="image-section">
                    <label>Превью (основное изображение):</label>
                    <div className="image-input-container">
                        <input
                            type="file"
                            onChange={(e) => setPreviewImage(e.target.files[0])}
                            className="form-input"
                            accept="image/*"
                        />
                        {previewImage && (
                            <div className="image-preview">
                                <img
                                    src={URL.createObjectURL(previewImage)}
                                    alt="Превью"
                                    className="preview-image"
                                />
                                <button
                                    type="button"
                                    className="remove-image-button"
                                    onClick={removePreviewImage}
                                >
                                    Удалить
                                </button>
                            </div>
                        )}
                    </div>

                    <label>Дополнительные изображения (до {MAX_ADDITIONAL_IMAGES}):</label>
                    <input
                        type="file"
                        multiple
                        onChange={(e) => setAdditionalImages([...e.target.files])}
                        className="form-input"
                        accept="image/*"
                    />

                    {additionalImages.length > 0 && (
                        <div className="image-preview">
                            {additionalImages.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt="Доп. изображение"
                                        className="preview-image"
                                    />
                                    <button
                                        type="button"
                                        className="remove-image-button"
                                        onClick={() => removeAdditionalImage(index)}
                                    >
                                        Удалить
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

              {loading && (
    <div className="loading-bar">
        <p>Пост создается...</p>
        <div className="dots">
            <span>.</span><span>.</span><span>.</span>
        </div>
    </div>
)}

                <button type="submit" disabled={loading} className="form-button">
                    {loading ? 'Создание...' : 'Создать пост'}
                </button>



                {postLink && (
                    <p className="success-message">
                        Пост успешно создан! <a href={postLink}>Перейти к посту</a>
                    </p>
                )}
            </form>
        </div>
    );
};

export default CreatePost;
