import React, { useState } from 'react';
import he from 'he';
import './EditPostModal.css';

const EditPostModal = ({ post, user, onClose, onSave }) => {
  const [editedPost, setEditedPost] = useState({
    title: he.decode(post.title.rendered),
    content: he.decode(post.content.rendered),
    price: post.acf?.price || '',
    location: post.acf?.location || '',
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedPost((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (!editedPost.title || !editedPost.content) {
      setError('Поля "Заголовок" и "Содержание" не могут быть пустыми.');
      return;
    }

    if (!user?.wp_user?.username || !user?.wp_user?.app_password) {
      setError('Не удалось получить данные пользователя.');
      return;
    }

    const postData = {
      title: editedPost.title,
      content: editedPost.content,
    };

    const acfData = {
      fields: {
        price: editedPost.price || '',
        location: editedPost.location || '',
      },
    };

    setIsLoading(true);
    try {
      const response = await fetch(`https://w.kypito.ru/wp-json/wp/v2/posts/${post.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${user.wp_user.username}:${user.wp_user.app_password}`)}`,
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error('Ошибка обновления поста.');
      }

      const acfResponse = await fetch(`https://w.kypito.ru/wp-json/acf/v3/posts/${post.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${user.wp_user.username}:${user.wp_user.app_password}`)}`,
        },
        body: JSON.stringify(acfData),
      });

      if (!acfResponse.ok) {
        throw new Error('Ошибка обновления ACF полей.');
      }

      onSave(post.id, editedPost);
      onClose();
    } catch (error) {
      setError('Ошибка при обновлении поста: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Редактировать пост</h2>
        {error && <p className="error-message">{error}</p>}
        {isLoading && <p>Сохранение...</p>}
        <label>Заголовок:</label>
        <input
          type="text"
          name="title"
          value={editedPost.title}
          onChange={handleChange}
        />
        <label>Цена:</label>
        <input
          type="number"
          name="price"
          value={editedPost.price}
          onChange={handleChange}
        />
        <label>Локация:</label>
        <input
          type="text"
          name="location"
          value={editedPost.location}
          onChange={handleChange}
        />
        <div className="modal-buttons">
         <button className="modal-button cancel" onClick={onClose}>Закрыть</button>
          <button className="modal-button save" onClick={handleSave}>Сохранить</button>
         
        </div>
      </div>
    </div>
  );
};

export default EditPostModal;