import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './PostDetail.css';
// import NextAndRelatedPosts from './NextAndRelatedPosts';  // Импорт компонента
import Posts from './Posts'; // Убедитесь, что путь к файлу правильный
import useLazyLoadImages from './useLazyLoadImages'; // Если файл в src


const PostDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [redirectMessage, setRedirectMessage] = useState(false);

  // Подключаем хук ленивой загрузки
    useLazyLoadImages();

    useEffect(() => {
        // Загружаем данные поста
         // Прокрутка страницы в начало при открытии нового поста
    window.scrollTo(0, 0);
    
        fetch(`https://w.kypito.ru/wp-json/wp/v2/posts/${id}?_embed`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Не удалось загрузить данные поста');
                }
                return response.json();
            })
            .then(data => {
                if (data.status === 'draft') {
                    setRedirectMessage(true);
                    setTimeout(() => navigate('/'), 3000); // Редирект через 3 секунды
                } else {
                    setPost(data);
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setRedirectMessage(true);
                setTimeout(() => navigate('/'), 3000); // Редирект через 3 секунды
                setLoading(false);
            });
    }, [id, navigate]);

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (redirectMessage) {
        return (
            <div>
                Пост видимо уже снят с публикации. Вы будете переадресованы на главную страницу.
            </div>
        );
    }

    if (error) {
        return <div>Ошибка: {error}</div>;
    }

    const categories = post._links["wp:term"]?.find(term => term.taxonomy === "category") || [];
    const tags = post._links["wp:term"]?.find(term => term.taxonomy === "post_tag") || [];
    const featuredImage = post._embedded?.["wp:featuredmedia"]?.[0]?.media_details?.sizes?.medium?.source_url || null;
    const authorUsername = post._embedded?.author?.[0]?.slug || 'unknown';
    const telegramNick = post._embedded?.author?.[0]?.description || ''; // Никнейм из биографии
    const messageText = `Здравствуйте! 👋\n\nЯ пишу вам с платформы Купито (@Kypito_bot) по вашему объявлению: "${post.title.rendered}".\n🔗 https://kypito.ru/post/${id}\n\nЯ очень заинтересован(а) в покупке. Когда можно обсудить детали?`;

    const handleShareToTelegram = () => {
        const postUrl = `https://kypito.ru/post/${id}`;
        const message = `✨ Смотри какое объявление я нашел на Купито! ✨\n\n${post.title.rendered}\n\n📍 Платформа по продаже ненужных вещей в ОАЭ.\n🔗 ${postUrl}\n🤖 Присоединяйся: @Kypito_bot`;
        const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(postUrl)}&text=${encodeURIComponent(message)}`;
        window.open(shareUrl, '_blank');
    };

    const handleShareToTelegramUserID = () => {
    const savedMessage = `Вот ссылка на пользователя, она работает только через Telegram приложение на телефоне к сожалению: tg://user?id=${authorUsername}\n\nА вот текст для вашего сообщения продавцу: \n\n ${messageText}`;
    const contactUrl = `https://t.me/share/url?url=text=${encodeURIComponent(savedMessage)}`;
    window.open(contactUrl, '_blank');
};


    const handleCopyLink = () => {
        const postUrl = `https://kypito.ru/post/${id}`;
        const message = `✨ Смотри какое объявление я нашел на Купито! ✨\n\n${post.title.rendered}\n\n📍 Платформа по продаже ненужных вещей в ОАЭ.\n🔗 ${postUrl}\n🤖 Присоединяйся: @Kypito_bot`;
        navigator.clipboard.writeText(message);
        alert('Ссылка на пост и текст успешно скопированы!');
    };

    const handleContactAuthor = () => {
        const postUrl = `https://kypito.ru/post/${id}`;
        const message = `Здравствуйте! 👋\n\nЯ пишу вам с платформы Купито (@Kypito_bot) по вашему объявлению: "${post.title.rendered}".\n🔗 ${postUrl}\n\nЯ очень заинтересован(а) в покупке. Когда можно обсудить детали? \n\n🤖 Вернуться в бот @Kypito_bot`;
        const contactUrl = `https://t.me/${authorUsername}?text=${encodeURIComponent(message)}`;
        window.open(contactUrl, '_blank');
    };

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /android/i.test(navigator.userAgent);

    return (
        <div className="post-detail-container">
<h1 className="post-detail-title">{post.title.rendered}</h1>

          

            {featuredImage && (
                <img
                    src={featuredImage}
                    alt={post.title.rendered}
                    className="post-detail-image"
                />
                
            )}

            

            <div
    className="post-detail-content"
    dangerouslySetInnerHTML={{
        __html: (() => {
            const content = post.content.rendered.replace(/data-src/g, 'src');
            const imgTags = content.match(/<img[^>]*>/g) || []; // Извлекаем все теги <img>
            const contentWithoutImages = content.replace(/<img[^>]*>/g, ''); // Убираем <img> из текста
            return imgTags.join('') + contentWithoutImages; // Склеиваем: картинки + остальной текст
        })(),
    }}
/>

 <p className="post-detail-date">
    Опубликовано: {new Date(post.date).toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    })}
</p>

            {post.acf?.price && (
                <p className="post-detail-price">
                    Цена: {post.acf.price} AED.
                </p>
            )}

            {post.acf?.location && (
                <div className="post-detail-location">
                    <p>Местоположение:&nbsp;
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(post.acf.location)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#007bff', textDecoration: 'none' }}
                        >
                            {post.acf.location}
                        </a>
                    </p>
                </div>
            )}

         

            <p className="post-detail-status">
                Статус товара: {post.acf?.status || 'Не указано'}
            </p>

            {categories.length > 0 && (
                <div className="post-detail-categories">
                    <h3>Категории:</h3>
                    <ul>
                        {categories.map(category => (
                            <li key={category.id}>{category.name}</li>
                        ))}
                    </ul>
                </div>
            )}

            {tags.length > 0 && (
                <div className="post-detail-tags">
                    <h3>Теги:</h3>
                    <ul>
                        {tags.map(tag => (
                            <li key={tag.id}>{tag.name}</li>
                        ))}
                    </ul>
                </div>
            )}

         
<p className="post-detail-author">
    {telegramNick ? (
        // Если в биографии указан ник Telegram
        <button
            onClick={() => {
                window.open(`https://t.me/${telegramNick}?text=${encodeURIComponent(messageText)}`, '_blank');
            }}
            style={{
                backgroundColor: 'transparent', // Прозрачный фон
                border: 'none',
                color: '#007bff', // Синий цвет текста
                cursor: 'pointer',
                padding: '0',
                textDecoration: 'none',
                fontSize: 'inherit',
                fontWeight: 'bold',
                display: 'inline', // Чтобы кнопка была в одной строке с текстом
            }}
        >
            Написать создателю объявления: @{telegramNick}
        </button>
    ) : (
        // Если биография пуста, показываем инструкцию
        <div className="post-detail-no-tg-nik">
   <p>
                У пользователя (<strong>tg://user?id={authorUsername}</strong>) который создал это объявление не указан Telegram-ник и поэтому с ним к сожалению не получится связаться, если он не добавил детали контактов в описании обьявления.</p>
</div>
    )}
</p>
        

           <p className="post-detail-modified">
    Последнее изменение: {new Date(post.modified).toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    })}
</p>

   <div className="post-detail-buttons">
                {isAndroid && (
                    <button
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.yandex.yango', '_blank')}
                        style={{
                            backgroundColor: '#34A853',
                            border: 'none',
                            padding: '10px 15px',
                            fontSize: '14px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            color: '#fff',
                            margin: '5px'
                        }}
                    >
                        Заказать доставку в Yango (Android)
                    </button>
                )}
                {isIOS && (
                    <button
                        onClick={() => window.open('https://apps.apple.com/us/app/yango-taxi-food-delivery/id1437157286', '_blank')}
                        style={{
                            backgroundColor: '#007bff',
                            border: 'none',
                            padding: '10px 15px',
                            fontSize: '14px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            color: '#fff',
                            margin: '5px'
                        }}
                    >
                        Заказать доставку в Yango (iOS)
                    </button>
                )}
            </div>


            <div className="post-detail-actions">
                <button className="share-telegram" onClick={handleShareToTelegram}>
                    Поделиться в Telegram
                </button>
                <button className="copy-link" onClick={handleCopyLink}>
                    Скопировать ссылку
                </button>
            </div>
                    {/* Компонент для следующего поста и списка постов */}
           {/* <NextAndRelatedPosts post={post} />*/}
                    {/* Встраиваем компонент Posts */}
            <div className="related-posts-section">
                <h2>Другие посты</h2>
                <Posts />
            </div>
        </div>
                     
    );
};

export default PostDetail;