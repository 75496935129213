import React from 'react';
import './AboutProject.css'; // Импортируем стили

function AboutProject() {
    return (
        <div className="about-project-container">
            <h2>О проекте</h2>
            <p>Добро пожаловать в Kypito — платформу для продажи ненужных вещей в ОАЭ!</p>
            <p>
                Здесь вы можете продавать ненужные вещи, искать услуги, находить попутчиков и сдавать жилье. 
                Мы стремимся сделать процесс максимально удобным, быстрым и безопасным.
            </p>
            <p>
                Если у вас есть вопросы, пожелания или предложения по улучшению, свяжитесь с нами через бота или напишите нам на почту.
            </p>

            {/* Кнопки для фидбэка и поддержки */}
            <div className="button-container">
                <a
                    href="https://t.me/Kypito_bot?start=feedback"
                    className="feedback-button"
                >
                    Оставить отзыв или сообщить о проблеме 📝
                </a>
                <a
                    href="https://t.me/Kypito_bot?start=donate"
                    className="donate-button"
                >
                    Поддержать разработчика 🌟
                </a>
            </div>

            {/* Кнопка для сохранения на главный экран */}
            <p>
                Для удобства вы можете сохранить приложение на главный экран телефона. 
            </p>
            <button
                onClick={() => {
                    if (window.Telegram?.WebApp) {
                        const tg = window.Telegram.WebApp;
                        tg.addToHomeScreen();
                    }
                }}
                className="save-button"
            >
                Сохранить Купито на главном экране 📌
            </button>

            {/* Правила платформы */}
            <div className="rules-container">
                <h3>Правила платформы Купито</h3>
                <p><strong>1. Основные правила</strong></p>
                <ul>
                    <li>Купито — это платформа для размещения объявлений о продаже товаров, услугах, аренде жилья и других законных предложений.</li>
                    <li>Будьте вежливы и уважайте других пользователей.</li>
                </ul>

                <p><strong>2. Что нельзя размещать</strong></p>
                <ul>
                    <li>Наркотики, оружие и другие запрещённые товары.</li>
                    <li>Мошенничество, поддельные документы и незаконные услуги.</li>
                    <li>Контент для взрослых, жестокое обращение с животными или призывы к насилию.</li>
                    <li>Не размещайте ложную информацию, спам или дублирующиеся объявления.</li>
                </ul>

                <p><strong>3. Сроки публикации</strong></p>
                <ul>
                    <li>Объявления автоматически снимаются через <strong>30 дней</strong>.</li>
                    <li>Если объявление всё ещё актуально, вы можете опубликовать его заново.</li>
                </ul>

                <p><strong>4. Ответственность</strong></p>
                <ul>
                    <li>Вы отвечаете за содержание своих объявлений.</li>
                    <li>Администрация может удалить объявление или заблокировать аккаунт за нарушение правил.</li>
                </ul>

                <p><strong>5. Наказания</strong></p>
                <ul>
                    <li>За незначительные нарушения — предупреждение.</li>
                    <li>За серьёзные нарушения (наркотики, мошенничество и т.д.) — <strong>постоянная блокировка</strong>.</li>
                </ul>

                <p><strong>6. Заключение</strong></p>
                <ul>
                    <li>Правила могут обновляться. Следите за изменениями!</li>
                    <li>Используя платформу, вы соглашаетесь с этими правилами.</li>
                </ul>
            </div>
        </div>
    );
}

export default AboutProject;