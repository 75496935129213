import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Posts.css'; // Подключение внешнего CSS-файла
import useLazyLoadImages from './useLazyLoadImages'; // Если файл в src

const FilterSection = ({ categories, onCategoryChange, selectedCategory }) => {
    const scrollContainerRef = useRef(null);
    const [showLeftGradient, setShowLeftGradient] = useState(false);
    const [showRightGradient, setShowRightGradient] = useState(true);

    // Автоскролл при загрузке
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        // Плавный автоскролл вправо
        setTimeout(() => {
            if (scrollContainer) {
                scrollContainer.scrollBy({ left: 50, behavior: 'smooth' });
            }
        }, 500);

        // Обновляем состояние градиентов при скролле
        const handleScroll = () => {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
            setShowLeftGradient(scrollLeft > 0);
            setShowRightGradient(scrollLeft < scrollWidth - clientWidth);
        };

        scrollContainer.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className={`filter-section-wrapper ${!showLeftGradient ? 'hide-gradient-left' : ''} ${
                !showRightGradient ? 'hide-gradient-right' : ''
            }`}
        >
            <div className="filter-section" ref={scrollContainerRef}>
                {categories.map((category) => (
                    <button
                        key={category.id}
                        onClick={() => onCategoryChange(category.id)}
                        className={`category-button ${
                            selectedCategory === category.id ? 'active' : ''
                        }`}
                    >
                        {category.name}
                    </button>
                ))}
            </div>
        </div>
    );
};

const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [priceRange, setPriceRange] = useState({ min: '', max: '' });
    const [sortOption, setSortOption] = useState('date-desc');

    const POSTS_PER_PAGE = 10;

    // Подключаем хук ленивой загрузки
    useLazyLoadImages();

    useEffect(() => {
        loadPosts();
    }, [page, selectedCategory]);

    useEffect(() => {
        loadCategories();
    }, []);

    const loadPosts = () => {
        if (loading || !hasMore) return;

        setLoading(true);
        const categoryFilter = selectedCategory !== 'all' ? `&categories=${selectedCategory}` : '';
        fetch(
            `https://w.kypito.ru/wp-json/wp/v2/posts?_embed&per_page=${POSTS_PER_PAGE}&page=${page}${categoryFilter}`
        )
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 400) setHasMore(false);
                    throw new Error('Не удалось загрузить посты');
                }
                return response.json();
            })
            .then((data) => {
                const newPosts = [...posts, ...data];
                setPosts(newPosts);
                setFilteredPosts(filterPosts(newPosts));
                setLoading(false);

                if (data.length < POSTS_PER_PAGE) setHasMore(false);
            })
            .catch((err) => {
                setError(err.message);
                setLoading(false);
            });
    };

    const loadCategories = () => {
        fetch('https://w.kypito.ru/wp-json/wp/v2/categories?per_page=20')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Не удалось загрузить категории');
                }
                return response.json();
            })
            .then((data) => {
                const validData = data.filter((category) => category.id && category.name);
                setCategories([{ id: 'all', name: 'Все' }, ...validData]);
            })
            .catch((err) => setError(err.message));
    };

    const filterPosts = (postsToFilter) => {
        let filtered = postsToFilter.filter((post) => {
            const price = parseFloat(post.acf?.price || 0);
            const isWithinPriceRange =
                (!priceRange.min || price >= priceRange.min) &&
                (!priceRange.max || price <= priceRange.max);
            return isWithinPriceRange;
        });

        if (sortOption === 'price-asc') {
            filtered = filtered.sort((a, b) => (a.acf?.price || 0) - (b.acf?.price || 0));
        } else if (sortOption === 'price-desc') {
            filtered = filtered.sort((a, b) => (b.acf?.price || 0) - (a.acf?.price || 0));
        } else if (sortOption === 'date-desc') {
            filtered = filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
        } else if (sortOption === 'date-asc') {
            filtered = filtered.sort((a, b) => new Date(a.date) - new Date(b.date));
        }

        return filtered;
    };

    useEffect(() => {
        setFilteredPosts(filterPosts(posts));
    }, [priceRange, sortOption, posts]);

    const handleCategoryChange = (categoryId) => {
        setSelectedCategory(categoryId);
        setPosts([]);
        setPage(1);
        setHasMore(true);
    };

    const handleLoadMore = () => setPage((prevPage) => prevPage + 1);

    useEffect(() => {
        const filtered = filterPosts(posts).filter((post) =>
            post.title.rendered.toLowerCase().includes(searchTerm.toLowerCase()) ||
            post.content.rendered.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredPosts(filtered);
    }, [searchTerm, posts]);

    if (error) return <div>Ошибка: {error}</div>;

    return (
        <div style={{ paddingTop: '0px', paddingBottom: '50px' }}>
            <div className="filter-bar">
                <input
                    type="text"
                    placeholder="Поиск по постам..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />

                <select
                    className="sort-select"
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                >
                    
                    <option value="price-asc">Цена: по возрастанию</option>
                    <option value="price-desc">Цена: по убыванию</option>
                    <option value="date-desc">Дата: от новых</option>
                    <option value="date-asc">Дата: от старых</option>
                </select>
            </div>

            <FilterSection
                categories={categories}
                onCategoryChange={handleCategoryChange}
                selectedCategory={selectedCategory}
            />

            <div className="posts-grid">
                {filteredPosts.map((post) => (
                    <div className="post-item" key={post.id}>
                        <Link
                            to={`/post/${post.id}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <img
                                src={
                                    post._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes
                                        ?.medium?.source_url || 'https://via.placeholder.com/300'
                                }
                                alt={post.title.rendered}
                            />
                            <h3>{post.title.rendered}</h3>

                            <div className="status-label">
                                <p>{post.acf?.status || 'Статус не указан'}</p>
                            </div>

                            <div className="post-details">
                                <p>
                                    <strong>Цена:</strong> {post.acf?.price || 'не указана'} AED
                                </p>
                                <p>
                                    <strong>Локация:</strong> {post.acf?.location || 'не указана'}
                                </p>
                                <p>
                                    <strong>Дата:</strong>{' '}
                                    {new Date(post.date).toLocaleString('ru-RU', {
                                        year: '2-digit',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>

            {hasMore && (
                <button onClick={handleLoadMore} className="load-more-button">
                    {loading ? 'Загрузка...' : 'Загрузить еще'}
                </button>
            )}
        </div>
    );
};

export default Posts;